import React from "react";
import LogoDesktop from "../../assets/images/social-master-logo-06-copy@3x.png";


const Footer = () => {
  return (
    <div className="Footer-container">
      <p className="Footer-copy">
        © Powered by{" "}
        <a target="_blank" href="https://socialmaster.com/" className="Footer-company">
          <img src={LogoDesktop} className="Footer-logo" alt="Logo" />
          Social Master
        </a>{" "}
        | <a href="https://socialmaster.com/pages/support" target="_blank">Frequently Asked Questions</a>
      </p>
    </div>
  );
};

export default Footer;
