import api from '../api';

export async function getProfile (socialCode) {
    try {
        const response = await api.get({ url:[ 'CODE', socialCode] });
        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export async function donwloadVCard(username,profile) {
    try {
        const response = await api.get({ url:[ 'VCARD', username, profile] });
        return response;
    } catch (error) {
        throw new Error(error);
    }
}