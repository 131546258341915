import * as api from './api';
import config from '../api/config';

class ProfileService {

    static async getProfileByCode(code) {
        return await api.getProfileByCode(code);
    }

    static async increaseSocialCounter(pmsID) {
        return await api.increaseSocialCounter(pmsID)
    }

    static async downloadProfileVCard(socialCode) {
        try {
            const response = await api.downloadProfileVCard(socialCode)
            const filename = response.headers["content-disposition"].split('"')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.target="_blank";
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        } catch (error){
            console.log({error});
        }
    }

    static async downloadProfileVCard2(socialCode) {
        window.location = `${config.url}/api/code2/${socialCode}/vcard`;
    }


    static generateErrorResponse(errorCode) {
        let message = '';

        switch (errorCode) {
        case 401:
            message = 'An error has occurred, please try again later.';
            break;
        case 500:
            message = 'A connection error may be happening, please try again later';
            break;
        default:
            message = 'Internal server error';
        }

        return message;
    }

    static onRequestFailed(exception) {
        throw exception;
    }

}

export default ProfileService;