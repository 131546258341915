import React from 'react';
import Container from 'react-bootstrap/Container';
import TitleWebComponent from '../../../components/TitleWebPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

const TemplateNotFound = ({ children, title }) => {
    return (
        <>
            <TitleWebComponent title={title} />
            <Header />
            <Container fluid>{children}</Container>
            <Footer />
        </>
    );
};

export default TemplateNotFound;
