import React from "react";
import { withRouter } from "react-router";
import LogoDesktop from "../../assets/brand/logo-desktop.png";
import LogoMobile from "../../assets/brand/logo-mobile.png";

const Header = (props) => {
  return (
    <div className="Header-container">
      <div
        className="Header-logo"
        onClick={() => console.log("click en el login")}
      >
        <img className="Header-logo--desktop" src={LogoDesktop} alt="Desktop logo" />
        <img className="Header-logo--mobile" src={LogoMobile} alt="Mobile logo" />
      </div>
      <a className="Header-button" href="https://socialmaster.com/shop">
        Buy Social Master
      </a>
    </div>
  );
};

export default withRouter(Header);
