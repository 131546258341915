import {
  all,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import {
  getProfileSuccess,
  getProfileFailed,
} from '../actions';
import {
  GET_PROFILE,
} from '../types';
import {
  getProfile as getProfileService
} from '../../services/profile';

function* getProfile(action) {
  try {
    const profile = yield call(getProfileService, action.payload);
    yield put(getProfileSuccess(profile));
  } catch (e) {
    yield put(getProfileFailed('Something went wrong'));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_PROFILE, getProfile),
  ]);
}
  