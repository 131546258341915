import React from 'react';
import { Container } from 'react-bootstrap';
import SingleRow from '../../../components/SingleRow';

const NotFound = () => {
    return (
        <Container>
            <SingleRow className="justify-content-center notFound text-center">
                <h1>Wooh! Page not found!</h1>
            </SingleRow>
        </Container>
    );
}

export default NotFound;
