import React from 'react';

const ProtectedLayout = ({ route: Route, template: Template, component: Component, ...props }) => {
    const MegaComponent = props => (<Template><Component {...props} /></Template>);
    return (
        <Route {...props} component={MegaComponent}/>
    );
};

export default ProtectedLayout;
