import axios from 'axios';
import { endpoints, socialMaster } from './endpoints';

const api = {
    endpoints,
    get: async function (options){
        const params = {
            ...options,
            url: this.decodeURL(options.url)
        };
        return new Promise((resolve, reject) => {
            axios.get(`${socialMaster}${params.url}`)
                .then(res=> {
                    if(res.status === 401){
                        return false;
                    }
                    resolve(res);
                } )
                .catch(e => reject(e) );
        });
    },
    post: async function (options){
        const params = {
            ...options,
            url: this.decodeURL(options.url)
        };
        return new Promise((resolve, reject) => {
            axios.post(`${socialMaster}${params.url}`)
                .then(res=> {
                    if(res.status === 401){
                        return false;
                    }
                    resolve(res);
                } )
                .catch(e => reject(e) );
        });
    },
    decodeURL(url) {
        let endpoint = endpoints[url[0]];
        if (url.length > 1) {
            const params = url.filter((param, index) => index !== 0);
            const format = require('string-template');
            endpoint = format(endpoint, params);
        }
        return endpoint;
    }
};

export default api;
