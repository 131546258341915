import React from 'react';
import { Container } from 'react-bootstrap';
import TitleWebComponent from '../../../components/TitleWebPage';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
const BuySocialMasterTemplate = ({ children, title }) => {
    return (
        <>
            <TitleWebComponent title={title} />
            <Header />
            <Container fluid>{children}</Container>
            <Footer />
        </>
    );
};

export default BuySocialMasterTemplate;
