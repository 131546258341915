import { combineReducers } from 'redux';
import { GET_PROFILE_SUCCESS, GET_PROFILE_FAILED } from '../types';

const initialState = {
  profile: null,
  message: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS: {
      return { ...state, profile: action.payload };
    }
    case GET_PROFILE_FAILED: {
      return { ...state, message: action.payload };
    }
    default:
      return state;
  }
};

const reducers = {
  rootReducer,
};

const allReducers = combineReducers(reducers);

export default allReducers;
