import React from "react";
import TitleWebComponent from "../../../components/TitleWebPage";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
const LandingTemplate = ({ children, title }) => {
  return (
    <>
      <TitleWebComponent title={title} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default LandingTemplate;
