import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { Switch, BrowserRouter } from "react-router-dom";
//styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/bcmaster.scss";

//components
import store from "./redux/store";
import Layout from "./Layouts";
import Loading from "./components/Loading";
import Landing from "./views/Open/Landing";
import BuySocialMaster from "./views/Open/BuySocialMaster";
import NotFoundComponent from "./views/Open/NotFound";
import TemplateNotFound from "./Layouts/templates/NotFoundTemplate";
import LandingTemplate from "./Layouts/templates/Landing";
import BuySocialMasterTemplate from "./Layouts/templates/BuySocialMaster";
//Protected and Public
import OpenRoute from "./security/OpenRoute";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            {/* OPEN ROUTER */}
            <Layout
              exact
              path="/:user"
              route={OpenRoute}
              template={LandingTemplate}
              component={Landing}
            />
            <Layout
              path="/landing/buySocialMaster"
              route={OpenRoute}
              template={BuySocialMasterTemplate}
              component={BuySocialMaster}
            />
            {/* COMPONENT 404 NOT FOUND */}
            <Layout
              route={OpenRoute}
              template={TemplateNotFound}
              component={NotFoundComponent}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
