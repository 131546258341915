import React from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router';
import SingleRow from '../../../components/SingleRow';

const BuySocialMaster = () => {
    return (
        <Container>
            <SingleRow className="justify-content-center buySocialMasterTextTitle text-center">
                <h1>COMMING SOON</h1>
            </SingleRow>
            <SingleRow className="justify-content-center buySocialMasterTextSubTitle text-center ">
                <h1>Our website is under construction</h1>
            </SingleRow>
        </Container>
    );
}

export default withRouter(BuySocialMaster);
