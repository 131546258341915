import {
  GET_PROFILE,
  GET_PROFILE_FAILED,
  GET_PROFILE_SUCCESS,
  DOWNLOAD_VCARD,
  DOWNLOAD_VCARD_FAILED,
  DOWNLOAD_VCARD_SUCCESS,
} from '../types';

export const getProfile = (user) => (
  {
    type: GET_PROFILE,
    payload: user,
  }
);
export const getProfileSuccess = (profile) => (
  {
    type: GET_PROFILE_SUCCESS,
    payload: profile,
  }
);
export const getProfileFailed = (message) => (
  {
    type: GET_PROFILE_FAILED,
    payload: message,
  }
);

export const donwloadVCard = (user) => (
  {
    type: DOWNLOAD_VCARD,
    payload: user,
  }
);
export const donwloadVCardSuccess = (profile) => (
  {
    type: DOWNLOAD_VCARD_SUCCESS,
    payload: profile,
  }
);
export const donwloadVCardFailed = (message) => (
  {
    type: DOWNLOAD_VCARD_FAILED,
    payload: message,
  }
);