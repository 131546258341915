//TODO: Migrar todos los pivoteos de Profile business/ personal a esta funcion.

import {isNil} from 'lodash';
let workAddress;
const buildCustomHeaderData = (profile) => {  
  if (profile.profileType === 'Personal') {
    workAddress = buildWorkAddressPersonal();
    return {
      name:
        profile.basicInfo != null
          ? (profile.basicInfo?.name + ' ' + (isNil(profile.basicInfo?.lastName)? '' : profile.basicInfo?.lastName))
          : '',
      workTitle: isNil(profile.basicInfo.workTitle)
        ? ''
        : profile.basicInfo.workTitle,
      companyName: isNil(profile.basicInfo.company)
        ? ''
        : profile.basicInfo.company,
      workAddress: isNil(workAddress) ? '' : workAddress,
    };
  } else {
    workAddress = buildWorkAddressBusiness();
    const firstBusiness = profile.business[0];
    return {
      name: (firstBusiness? firstBusiness.name + ' ' + (isNil(firstBusiness.lastName) ? '' : firstBusiness.lastName)
          : ''),
      workTitle: firstBusiness && !isNil(firstBusiness.workTitle)
          ? firstBusiness.workTitle
          : '',
      companyName: isNil(firstBusiness?.companyName)
        ? ''
        : firstBusiness.companyName,
      workAddress: isNil(workAddress) ? '' : workAddress,
      bio: isNil(firstBusiness?.bio)
      ? ''
      : firstBusiness.bio,
    };
  }

  function buildWorkAddressPersonal() {
    let workAddress;

    if (
      !isNil(profile.basicInfo.zipCode) &&
      !isNil(profile.basicInfo.zipCode.city)
    ) {
      workAddress = profile.basicInfo.zipCode.city;
    }
    if (
      !isNil(profile.basicInfo.zipCode) &&
      !isNil(profile.basicInfo.zipCode.state)
    ) {
      if (!isNil(workAddress)) {
        workAddress += ', ' + profile.basicInfo.zipCode.state;
      } else {
        workAddress = profile.basicInfo.zipCode.state;
      }
    }
    return workAddress;
  }

  function buildWorkAddressBusiness() {
    let workAddress;
    const firstBusiness = profile.business[0];
    if(firstBusiness && !isNil(firstBusiness?.workAddress)){
      if (
        !isNil(firstBusiness.workAddress.city)) {
        workAddress = firstBusiness.workAddress.city;
      }
      if (
        !isNil(firstBusiness.workAddress.state) &&
        !isNil(firstBusiness.workAddress.state)
      ) {
        if (!isNil(workAddress)) {
          workAddress += ', ' + firstBusiness.workAddress.state;
        } else {
          workAddress = firstBusiness.workAddress.state;
        }
      }
    }
    return workAddress;
  }

};

export {buildCustomHeaderData};
