import api from "../../api";

export async function getProfile(username) {
  return await api.get({ url:[ 'PROFILE', username] });
}

export async function getProfileByCode(socialCode) {
  return await api.get({ url:[ 'CODE', socialCode] });
}

export async function increaseSocialCounter(pmsID) {
  return await api.post({ url:[ 'SOCIALCOUNTER', pmsID] });
}

export async function downloadProfileVCard(socialCode) {
  return await api.get({ url:[ 'VCARD', socialCode]});
}