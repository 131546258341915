import React from 'react'
import { Row } from 'react-bootstrap'

const CenterRow = ({ children, className }) => {
    return (
        <Row className={className} >
            {children}
        </Row>
    )
}

export default CenterRow
