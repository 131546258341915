import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import ProfileService from "../../services/profile/index";
import { validURL, validURLString } from "../../utils/validations.js";

const SocialButton = ({ net, iconImage, imageStyle, index }) => {
  const url = require(`../../assets/icons/png/${iconImage}.png`);
  const [contructorUrl, setConstructorUrl] = useState("");

  const increaseSocialCounter = async (net) => {
    try {
      if (validURL(`${net.handle}`)) {
        await ProfileService.increaseSocialCounter(net.socialid);
      } else {
        throw "Invalid url network.";
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    switch (net) {
      default:
        setConstructorUrl(`${net.url}${net.handle}`);
    }
  }, []);

  useEffect(() => {
    switch (net.socialName) {
      case "WhatsApp":
        setConstructorUrl(`https://wa.me/${net.handle}/`);
        break;
      case "Skype":
        setConstructorUrl(`skype:${net.handle}?call`);
        break;
      case "Wechat":
        setConstructorUrl(`weixin://dl/chat?${net.handle}`);
        break;
      case "Viber":
        setConstructorUrl(`viber://chat/?number=${net.handle}/`);
        break;
      case "Line":
        setConstructorUrl(`https://line.me/R/oaMessage/${net.handle}/`);
        break;
      case "Indeed":
        setConstructorUrl(`https://my.indeed.com/p/${net.handle}/profile`);
        break;
      case "Tumblr":
        setConstructorUrl(`https://${net.handle}.tumblr.com/`);
        break;
      case "Facebook Messenger":
        setConstructorUrl(`https://m.me/${net.handle}/`);
        break;
      case "PhoneNumber":
        setConstructorUrl(`sms:${net.handle}`);
        break;
      case "Email":
        setConstructorUrl(`mailto:${net.handle}`);
        break;
      case "Paypal":
        setConstructorUrl(validURLString(net.handle));
        break;
      case "Facebook":
        setConstructorUrl(validURLString(net.handle));
        break;
      case "Website":
        setConstructorUrl(validURLString(net.handle));
        break;
      default:
        setConstructorUrl(validURLString(net.url + net.handle));
        break;
    }
  }, []);

  return (
    <a
      className="Social-Grid-item"
      key={`list-element-${index}`}
      target="_blank"
      href={contructorUrl}
      onClick={() => increaseSocialCounter(net)}
    >
      <Image src={url} className={imageStyle} />
    </a>
  );
};

export default SocialButton;
