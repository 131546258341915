import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router";
import SocialButton from "../../../components/SocialButton";
import { socialMaster } from "../../../api/endpoints";
import { map, isEmpty } from "lodash";
import ProfileService from "../../../services/profile/index";
import Helmet from "react-helmet";
import {
  formatURL,
  validURLString,
  regexWebsiteParams,
} from "../../../utils/validations.js";
import { buildCompleteName } from "../../../utils/helpers";
import { buildCustomHeaderData } from "../../../utils/profileDataHelper";

const getUrlFormat = (type, handle, url) => {
  const r = {
    WhatsApp: `https://wa.me/${handle}/`,
    Skype: `skype:${handle}?call`,
    Wechat: `weixin://dl/chat?${handle}/`,
    Viber: `viber://chat/?number=${handle}/`,
    Line: `https://line.me/R/oaMessage/${handle}/`,
    Indeed: `https://my.indeed.com/p/${handle}/profile`,
    Tumblr: `https://${handle}.tumblr.com/`,
    "Facebook Messenger": `https://m.me/${handle}/`,
    PhoneNumber: `sms:${handle}`,
    Email: `mailto:${handle}`,
    Paypal: validURLString(handle)
  }
  return r[type] ?? validURLString(url + handle)
}
const Landing = () => {
  const [networks, setNetworks] = useState("");
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [active, setActive] = useState(null);
  const [status, setStatus] = useState(null);
  const [featuredLinks, setfeaturedLinks] = useState([]);
  const [directUrl, setDirectUrl] = useState("");
  const { user } = useParams();

  const directFormatUrl = (net) => getUrlFormat(net.socialName, net.handle, net.url);

  const getUserProfile = async () => {
    try {
      const response = await ProfileService.getProfileByCode(user);

      const profileData = response.data;
      setActive(profileData.active); 
      if (!profileData) {
        setLoading(false);
        setStatus("not_found");
      }
      setfeaturedLinks(
        profileData.featuredLinks ? profileData.featuredLinks : []
      );

      const networks = [
        ...profileData.socialMedia,
        ...profileData.communication,
        ...profileData.payments,
        ...profileData.music,
        ...profileData.ecommerce,
        ...profileData.custom,
        ...profileData.productivity,
        ...profileData.goodsAndServices,
      ].filter((network) => network.key == null);

      if (profileData.directEnabled && (profileData.directUrl !== "" && profileData.directUrl !== null)) {
        setStatus("direct");
        const directNet = networks.filter(
          (network) => profileData.directUrl == network.socialName
        );

        directNet[0].url =
          directNet[0].url == null ? "" : directNet[0].url.trim();

        const formattedUrl = directFormatUrl(directNet[0]);

        switch (directNet[0].socialName) {
          case "Skype":
          case "Wechat":
          case "Viber":
          case "Email":
          case "PhoneNumber":
            window.location.replace(formattedUrl);
            break;
          default:
            if (regexWebsiteParams.test(formattedUrl)) {
              setLoading(false);
              if (directNet.length > 0) {
                setLoading(false);
                setDirectUrl(formattedUrl);
                window.location.replace(formattedUrl);
              }
            } else {
              setLoading(false);
              setDirectUrl(formattedUrl);
              setStatus("invalid_url");
            }
        }
      } else {
        setLoading(false);
        setNetworks(networks);
        setProfile(profileData);
      }
    } catch (exception) {
      console.log({ exception });
    }
  };

  const downloadVCard = async () => {
    return await ProfileService.downloadProfileVCard2(user);
  };

  const renderNetwork = (net, index) => {
    return (
      <SocialButton
        net={net}
        iconImage={net.socialName}
        title={net.socialName}
        index={index}
      />
    );
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  if (loading) {
    return (
      <div className="NotFound">
        <h1 className="NotFound-title">Loading...</h1>
      </div>
    );
  } else if (status === "invalid_url") {
    return (
      <div className="NotFound">
        <h1 className="NotFound-title">
          Something wrong with the url {directUrl}
        </h1>
      </div>
    );
  } else if (status === "not_found") {
    return (
      <div className="NotFound">
        <h1 className="NotFound-title">we can't find the user {user}</h1>
      </div>
    );
    } else if (!active) {
      return (
        <div className="NotFound">
          <h1 className="NotFound-title">This profile is HIDDEN.</h1>
        </div>
      );
  }
  const name = profile? buildCustomHeaderData(profile) : ''
  const zipCode = profile?.basicInfo?.zipCode
  const business = profile?.business?.[0]
  return profile && profile.basicInfo ? (
    <>
      <Helmet>
        <meta name="format-detection" content="telephone=yes" />
      </Helmet>
      <div className="Profile-container">
        <div
          className="Profile-image"
          style={{
            backgroundImage: profile.avatarUrl
              ? `url(${socialMaster}/${profile.avatarUrl})`
              : `url(${require("../../../assets/images/selfieIlustration.png")})`,
          }}
        ></div>

        <div className="Profile-description">
          <h1 className="Profile-name">{buildCustomHeaderData(profile)?.name}</h1>
          <p className="Profile-profesion">{ buildCustomHeaderData(profile)?.workTitle }
          </p>
          <p className="Profile-company">{buildCustomHeaderData(profile)?.companyName}
          </p>
          <h4 className="Profile-address">
            {buildCustomHeaderData(profile)?.workAddress}
          </h4>
        </div>
      </div>

      <div className="Profile-bio">
        <p>{profile.profileType === 'Personal' ? profile.basicInfo.bio : buildCustomHeaderData(profile)?.bio}</p>
      </div>

      <div className="Profile-button">
        <button
          className="Profile-button--component"
          onClick={() => downloadVCard()}
        >
          Add as a contact
        </button>
      </div>

      {featuredLinks.map((link) =>
        !isEmpty(link.value) &&
          !isEmpty(link.title) &&
          regexWebsiteParams.test(link.value) ? (
          // !validURL(link.value)
          <div className="Profile-button">
            <a
              className="Profile-button--featured"
              target="_blank"
              href={`${formatURL(link.value)}`}
            >
              {link.title}  
            </a>
          </div>
        ) : null
      )}


      <div className="Social-Grid-container">
        {networks &&
          map(networks, (network, index) => renderNetwork(network, index))}
      </div>
    </>
  ) : null;
};

export default withRouter(Landing);
